import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Chart, Line } from 'react-chartjs-2'
import Table from 'rc-table'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faStar, faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import posthog from 'posthog-js'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'

import palette from 'google-palette'
import timeAgo from 'epoch-timeago'

import { SEASON_2024_START, DIVS_PER_TIER, TIER_OFFSETS, OP_GG_REGIONS, SCALE_MAP } from './Constants'
import styles from './Leaderboard.module.css'


Chart.defaults.global.defaultFontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, Helvetica, Arial, sans-serif'
Chart.defaults.global.defaultFontColor = '#949ba1'


const formatRiotId = (riot_id, region, nicknames) => {
  let name
  let tagline
  if (riot_id.includes('#')) {
    [name, tagline] = riot_id.split('#')
  } else {
    name = riot_id
    tagline = null
  }

  const summonerName = nicknames[riot_id] ? <em>{ nicknames[riot_id] }</em> : name
  const riotId = <span>{ summonerName }{tagline ? <span className={ styles.tagline }>#{ tagline }</span> : null}</span>
  const normalizedName = name.replace(/\s+/g, '')
  const opgg_search = tagline != null ? `${normalizedName}-${tagline}` : normalizedName
  const opgg = `http://${ OP_GG_REGIONS[region] }.op.gg/summoner/userName=${ opgg_search }`
  const opggTitle = `View ${name}'s profile on OP.GG`
  return <a href={ opgg } rel="noopener noreferrer" target="_blank" title={ opggTitle }>{ riotId }</a>
}

const rankToValue = (tier, division) => {
  let offset = TIER_OFFSETS[tier] * DIVS_PER_TIER
  if (TIER_OFFSETS[tier] === TIER_OFFSETS.MASTER) {
    offset -= (DIVS_PER_TIER - 1)
  }

  return (offset + (DIVS_PER_TIER - division)) * 100
}

const Dot = ({ color, hidden, onClick, disabled }) => {
  const style = { borderColor: color }
  if (!hidden) {
    style.backgroundColor = color
  }

  let dotClass = styles.dot

  if (disabled) {
    dotClass = styles.disabledDot
  } else if (onClick == null) {
    dotClass = styles.circle
  }

  return <span onClick={ onClick } className={ dotClass } style={ style } />
}

Dot.propTypes = {
  color: PropTypes.string,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

const HoverTip = ({ text, contents, position, distance }) => {
  return (
    <Tooltip
      title={ text }
      position={ position }
      distance={ distance }
      arrow
      trigger="mouseenter"
      size="small"
      duration={ 100 }
    >
      { contents }
    </Tooltip>
  )
}

HoverTip.propTypes = {
  text: PropTypes.string.isRequired,
  contents: PropTypes.element.isRequired,
  position: PropTypes.string,
  distance: PropTypes.number,
}

class Leaderboard extends Component {
  constructor(props) {
    super(props)

    // Attempt to read timescale choice from localStorage
    const savedScale = localStorage.getItem('scale')
    const scale = SCALE_MAP[savedScale] ? savedScale : '1m'

    this.state = {
      sname: null,
      slug: null,
      starred: false,
      dataset: {},
      nicknames: {},
      reset: {},
      order: [],
      region: null,
      loading: false,
      error: null,
      refreshing: false,
      disableRefresh: false,
      isMobile: false,
      scale,
      hiddenDatasets: {},
      hoveredDatasets: new Set(),
    }
  }

  loadChart = ({ slug, name, region }) => {
    this.setState({ loading: true })

    let url
    let sname
    if (slug) {
      url = `/api/leaderboard/${slug}`
    } else {
      url = `/api/summoner/${region}/${name}`
      slug = `${region}/${name}`
      sname = name
    }

    fetch(url)
      .then(res => res.json())
      .then(
        (res) => {
          let starred = false
          const starredLeaderboards = JSON.parse(localStorage.getItem('starred') || '[]')

          for (let i = 0; i < starredLeaderboards.length; i++) {
            if (starredLeaderboards[i].slug === slug) {
              starred = true
              break
            }
          }

          // Execute relevant RGEA functions
          window.rgea('lolpid', res.region)
          // TODO: Push PUUIDs to frontend and send as analytics on single-user leaderboards
          // window.rgea('uid', 'puuid')
          // window.rgea('lolaid', 'accountId')
          window.rgea('anonymous', false)

          this.setState({
            sname,
            slug,
            starred,
            leaderboardName: res.name || (res.order != null ? res.order.join(', ') : null),
            dataset: res.summoners,
            nicknames: res.nicknames,
            reset: res.reset,
            order: res.order,
            region: res.region,
            lastRefresh: res.lastRefresh,
            error: null,
            loading: false,
            refreshing: false,
          })
        },
        (err) => {
          this.setState({
            sname: null,
            slug: null,
            starred: false,
            leaderboardName: null,
            dataset: {},
            nicknames: {},
            reset: {},
            order: [],
            region: null,
            error: 'Failed to load data. Please try again later.',
            loading: false,
            refreshing: false,
          })
        }
      )
  }

  componentDidCatch(error, info) {
    this.setState({ error: 'Error rendering leaderboard. :(' })
  }

  loadAlert = () => {
    const lastCheck = localStorage.getItem('lastCheck')

    // Only check for new announcements once an hour
    if ((new Date() - lastCheck) < 1000 * 60 * 60) {
      const announcement = JSON.parse(localStorage.getItem('announcement') || '{}')

      const hiddenAlert = localStorage.getItem('hiddenAlert')
      if (announcement.id === hiddenAlert) {
        return
      }

      this.setState({ announcement: announcement })
      return
    }

    fetch('/api/announcement')
      .then(res => res.json())
      .then(resp => {
        if (resp.id === localStorage.getItem('hiddenAlert')) {
          return
        }

        localStorage.setItem('announcement', JSON.stringify(resp))
        localStorage.setItem('lastCheck', new Date() - 0)

        this.setState({
          announcement: {
            title: resp.title,
            url: resp.url,
            id: resp.id,
          },
        })
      }).catch(() => {})
  }

  toggleDataset = (index) => {
    const { hiddenDatasets } = this.state
    const chartInstance = this.refs.chart.chartInstance // eslint-disable-line react/no-string-refs

    if (chartInstance == null) {
      return
    }

    const ds = chartInstance.data.datasets[index]
    ds.hidden = hiddenDatasets[index] = !hiddenDatasets[index]

    chartInstance.update()
    this.setState({ hiddenDatasets })
  }

  setScale = (value) => {
    const { scale, slug } = this.state

    if (value === scale) {
      return
    }

    this.setState({ scale: value })
    localStorage.setItem('scale', value)
    posthog.capture('changed_scale', {
      leaderboard: slug,
      scale: value,
    })
  }

  pollRedirect = (url, tries) => {
    const { slug, region, sname } = this.state

    if (tries <= 0) {
      alert('Unable to refresh leaderboard. Please try again later.')
      this.setState({ refreshing: false, disableRefresh: true })
      return
    }

    fetch(url)
      .then(res => {
        res.json().then(resp => {
          if (res.status === 200 && resp.lastRefresh != null) {
            this.setState({ refreshing: false, lastRefresh: resp.lastRefresh })
            if (sname) {
              this.loadChart({ region, name: sname })
            } else {
              this.loadChart({ slug })
            }

            return
          } else if (res.status >= 400) {
            // Not a true throttle on forcing people not to refresh, but
            // keep the refresh icon disabled until the next page load
            // to disincentivize spamming it.
            this.setState({ refreshing: false, disableRefresh: true })

            // If the refresh endpoint returned 404, the actual response is
            // just an empty object, so replace it with an error message.
            if (res.status === 404) {
              resp = 'Unable to refresh leaderboard. Please try again later.'
            }

            // Put this on a very slight timeout so that the refresh
            // spinner can stop before the alert actually appears.
            setTimeout(() => alert(resp), 5)
            return
          } else {
            // Check again in 2.5 seconds
            setTimeout(() => this.pollRedirect(url, tries - 1), 2500)
          }
        })
      }).catch(err => {
        this.setState({ refreshing: false, disableRefresh: true })
      })
  }

  refreshLeaderboard = () => {
    const { slug, sname, refreshing } = this.state

    if (refreshing) {
      return
    }

    this.setState({ refreshing: true })

    posthog.capture('refreshed_leaderboard', {
      leaderboard: slug,
    })

    const endpoint = sname ? 'summoner' : 'leaderboard'

    // `/api/leaderboard/<slug>/refresh` or `/api/summoner/<region>/<name>/refresh`
    fetch(`/api/${endpoint}/${slug}/refresh`)
      .then(res => {
        if (res.status === 202 && res.headers.get('Location') != null) {
          const statusUrl = res.headers.get('Location')
          setTimeout(() => this.pollRedirect(statusUrl, 6), 1000)
        } else if (res.status === 400 && res.headers.get('Last-Refresh') != null) {
          const lastRefresh = parseInt(res.headers.get('Last-Refresh'), 10)
          this.setState({ refreshing: false, lastRefresh })
        } else {
          this.setState({ refreshing: false, disableRefresh: true })

          // Put this on a very slight timeout so that the refresh
          // spinner can stop before the alert actually appears.
          setTimeout(() => alert('Unable to refresh leaderboard. Please try again later.'), 5)
        }
        return
      }).catch(err => {
        this.setState({ refreshing: false, disableRefresh: true })
      })
  }

  highlightDataset = (name) => {
    const { hoveredDatasets, dataset } = this.state

    if (dataset[name] == null) {
      return
    }

    const next = hoveredDatasets.add(name)
    this.setState({ hoveredDatasets: next })
  }

  unhighlightDataset = (name) => {
    const { hoveredDatasets, dataset } = this.state

    if (dataset[name] == null) {
      return
    }

    hoveredDatasets.delete(name)
    this.setState({ hoveredDatasets })
  }

  toggleStar = () => {
    const { starred, slug, region, order, leaderboardName } = this.state

    const starredLeaderboards = JSON.parse(localStorage.getItem('starred') || '[]')

    if (starred) {
      for (let i = 0; i < starredLeaderboards.length; i++) {
        if (starredLeaderboards[i].slug === slug) {
          starredLeaderboards.splice(i, 1)
        }
      }
      posthog.capture('starred_leaderboard', { leaderboard: slug })
    } else {
      starredLeaderboards.push({ leaderboardName, region, summoners: order, slug })
      posthog.capture('unstarred_leaderboard', { leaderboard: slug })
    }

    localStorage.setItem('starred', JSON.stringify(starredLeaderboards))

    this.setState({ starred: !starred })
  }

  dismissAlert = () => {
    const { announcement } = this.state

    if (announcement == null) {
      return
    }

    localStorage.setItem('hiddenAlert', announcement.id)
    this.setState({ hideAlert: true })
  }

  componentDidMount() {
    const { match } = this.props
    window.addEventListener('resize', this.handleWindowSizeChange)

    if (window.innerWidth <= 500) {
      this.setState({ isMobile: true })
    }

    this.loadAlert()
    this.loadChart(match.params)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ isMobile: window.innerWidth <= 500 })
  }

  render() {
    const {
      starred, loading, leaderboardName, dataset, nicknames, reset, order, region, scale, hideAlert,
      announcement, refreshing, lastRefresh, disableRefresh, isMobile, hiddenDatasets, hoveredDatasets,
      error, slug
    } = this.state

    if (error) {
      return <span>{ error }</span>
    }

    if (loading) {
      return <Loader type="TailSpin" color="#999" height="64" width="64" />
    }

    if (order == null || order.length === 0) {
      return <span>Invalid leaderboard link.</span>
    }

    const colors = palette('mpn65', order.length).map(c => `#${c}`)

    let selectedMin = new Date()
    selectedMin.setDate(selectedMin.getDate() - SCALE_MAP[scale])

    let minDate = new Date()
    let maxDate = new Date()

    let hasData = false
    let minRank = 1e6
    let maxRank = -50

    const isCurrentSeason = (name) => {
      return (entry) => {
        if (scale === 'max') {
          return true
        }

        if (reset[name]) {
          return new Date(reset[name]) <= new Date(entry.date)
        }

        return new Date(dataset[name][0].date) >= SEASON_2024_START
      }
    }

    const data = {
      datasets: Object.entries(dataset).map(([name, values], i) => {
        let lineWidth = 2
        let color = order.length === 1 ? '#fce84e' : colors[order.indexOf(name)]
        if (hoveredDatasets.size > 0) {
          if (hoveredDatasets.has(name)) {
            lineWidth = 3
          } else {
            color = color + '22' // make other lines more transparent
          }
        }

        let pointRadius = 2
        if (scale === 'max' || (isMobile && scale === '3m')) {
          pointRadius = 0
        } else if (scale === '3m' || (isMobile && scale === '2m')) {
          pointRadius = 1
        }

        const filtered = values.filter(isCurrentSeason(name))

        return {
          label: name,
          data: filtered.map(entry => {
            // Graph any Div V rank as Div IV, 0 LP with a square point style
            let div = entry.division
            let lp = entry.lp

            if (div > DIVS_PER_TIER) {
              div = DIVS_PER_TIER
              lp = 0
            }

            const date = new Date(entry.date)
            minDate = Math.min(minDate, date)
            maxDate = Math.max(maxDate, date)
            date.setHours(24, 0, 0, 0)

            // Subtract 50 to render points properly with the `offsetGridLines` option.
            const rankScore = rankToValue(entry.tier, div) + lp - 50

            if (date >= selectedMin && !hiddenDatasets[i]) {
              hasData = true
              minRank = Math.min(minRank, rankScore)
              maxRank = Math.max(maxRank, rankScore)
            }

            return {
              x: date,
              y: rankScore,
            }
          }),
          borderColor: color,
          pointStyle: filtered.map(entry => entry.division === 5 ? 'rect' : 'circle'),
          pointBackgroundColor: filtered.map(entry => entry.series != null ? '#212529' : color),
          namedData: filtered.map(entry => `${entry.tier.toTitleCase()} ${entry.division} (${entry.lp} LP)`),
          borderWidth: lineWidth,
          pointRadius: pointRadius,
        }
      }),
    }

    const scaleMin = Math.max(minDate, selectedMin)
    const daysShown = (maxDate - scaleMin) / 86400000 // ms in a day

    let unit
    if (daysShown < 22) {
      unit = 'day'
    } else if (daysShown < 100) {
      unit = 'week'
    } else {
      unit = 'month'
    }

    // Division labels don't render unless at least one of these are here...
    minRank = Math.floor(minRank / 100) * 100
    maxRank = Math.ceil(maxRank / 100) * 100

    // Fix for leaderboards with only a single entry (the chart doesn't render 0 units of y-axis).
    if (minRank === maxRank) {
      minRank -= 50
      maxRank += 50
    }

    const options = {
      elements: {
        line: {
          fill: false,
          tension: 0,
        },
      },
      maintainAspectRatio: true,
      spanGaps: true,
      hover: {
        mode: 'point',
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#40464b',
            zeroLineColor: '#40464b',
          },
          type: 'time',
          time: {
            unit: unit,
            min: scaleMin,
            displayFormats: {
              week: 'MMM D',
              month: 'MMM D',
            },
          },
        }],
        yAxes: [{
          gridLines: {
            drawTicks: false,
            offsetGridLines: true,
            color: '#40464b',
            zeroLineColor: '#40464b',
          },
          ticks: {
            min: minRank,
            max: maxRank,
            stepSize: 100,
            callback: (value) => {
              if (value % 100 !== 0) {
                return null
              } else {
                let div = DIVS_PER_TIER - (Math.trunc(value / 100) % DIVS_PER_TIER)

                const n = (Math.min(Math.trunc(value / (DIVS_PER_TIER * 100)), TIER_OFFSETS.MASTER))
                const tierMap = ['Iron ', 'Bronze ', 'Silver ', 'Gold ', 'Platinum ', 'Emerald ', 'Diamond ', 'Master']

                let tier = tierMap[n]
                if (isMobile) {
                  tier = tier === 'Master' ? '' : tier[0]
                }

                // Use LP instead of division for Master+
                if (n === TIER_OFFSETS.MASTER) {
                  div = ` ${value - (TIER_OFFSETS.MASTER * DIVS_PER_TIER) * 100} LP`
                }

                return `${tier}${div}   `
              }
            },
          },
        }],
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'point',
        itemSort: (a, b, data) => {
          if (a.y < b.y) {
            return -1
          } else if (a.y > b.y) {
            return 1
          } else {
            const aName = data.datasets[a.datasetIndex].label
            const bName = data.datasets[b.datasetIndex].label
            return aName.localeCompare(bName)
          }
        },
        callbacks: {
          title: (value, data) => value.map(v => data.datasets[v.datasetIndex].label).join(', '),
          label: (value, data) => data.datasets[value.datasetIndex].namedData[value.index],
          labelColor: (value, chart) => {
            const color = chart.data.datasets[value.datasetIndex].borderColor
            return {
              borderColor: color,
              backgroundColor: color,
            }
          },
        },
      },
    }

    const columns = [
      {
        dataIndex: 'name',
        className: styles.summonerColumn,
      }, {
        dataIndex: 'delta',
        className: styles.deltaColumn,
      }, {
        dataIndex: 'rank',
        className: styles.rankColumnDesktop,
      }, {
        dataIndex: 'miniRank',
        className: styles.rankColumnMobile,
      },
    ]

    // Dataset indices map to non-filtered data
    let indices = {}
    let filteredData = {}

    Object.entries(dataset).forEach(([name, value], i) => {
      indices[name] = i
      const vals = value.filter(isCurrentSeason(name))

      if (vals.length > 0) {
        filteredData[name] = vals
      }
    })

    const visibleDatasets = Object.keys(filteredData).length
    const visibleDots = visibleDatasets - Object.values(hiddenDatasets).filter(v => v).length

    let tableData = Object.entries(filteredData).map(([name, value]) => {
      const entry = value[value.length - 1] // get most recent entry
      const link = formatRiotId(name, region, nicknames)

      const color = order.length === 1 ? '#fce84e' : colors[order.indexOf(name)]

      const i = indices[name]

      // Disable hiding the last unhidden dataset to prevent chart from disappearing
      let toggleFn
      if (hiddenDatasets[i] || visibleDots !== 1) {
        toggleFn = () => {
          this.toggleDataset(i)
          posthog.capture('toggled_dataset', {
            leaderboard: slug,
          })
        }
      }

      const legendDot = <Dot onClick={ toggleFn } color={ color } hidden={ hiddenDatasets[i] } />

      const currValue = rankToValue(entry.tier, entry.division) + entry.lp

      let delta
      if (value.length >= 2) {
        const prevEntry = value[value.length - 2]
        const prevValue = rankToValue(prevEntry.tier, prevEntry.division) + prevEntry.lp

        const dLP = currValue - prevValue
        const change = (dLP >= 0) ? `+${dLP}` : dLP

        let deltaColour

        if (dLP > 0) {
          deltaColour = '#00cc66'
        } else if (dLP < 0) {
          deltaColour = '#ff5050'
        } else {
          deltaColour = '#aaa'
        }

        if (entry.wins != null || entry.losses != null) {
          const hoverText = `${change} LP today  (${entry.wins || 0}W&ndash;${entry.losses || 0}L)`

          delta = (
            <HoverTip
              text={ hoverText }
              position="top"
              distance={ 6 }
              contents={ <span style={ { color: deltaColour } }>{ change }</span> }
            />
          )
        }
      }

      let seriesWins = 0
      let seriesLosses = 0
      const series = (entry.series || '').split('').map((c, i) => {
        switch (c) {
        case 'W':
          seriesWins += 1
          return <span key={ i } style={ { color: '#00cc66' } } className={ styles.diamond }>&#x25C6;</span>
        case 'L':
          seriesLosses += 1
          return <span key={ i } style={ { color: '#ff5050' } }>&#x00D7;</span>
        default:
          return <span key={ i } style={ { color: '#666' } }>-</span>
        }
      })

      const seriesText = `Promo Series: ${seriesWins}W&ndash;${seriesLosses}L (${entry.lp} LP)`

      const seriesSpan = (
        <HoverTip
          text={ seriesText }
          position="top"
          distance={ 6 }
          contents={ <span className={ styles.series }>{ series }</span> }
        />
      )

      // Hack to force 100 LP players to appear below all 0 LP from the tier above
      const sortLP = currValue - (entry.lp === 100 ? 0.5 : 0)

      let rank
      let miniRank
      let titleRank
      if (TIER_OFFSETS[entry.tier] === TIER_OFFSETS['MASTER']) {
        const shortTier = entry.tier === 'GRANDMASTER' ? 'GM' : entry.tier[0]
        rank = `${entry.tier.toTitleCase()} (${entry.lp} LP)`
        miniRank = `${shortTier} (${entry.lp} LP)`
        titleRank = rank
      } else {
        titleRank = `${entry.tier.toTitleCase()} ${ entry.division } (${entry.lp} LP)`

        if (series.length > 0) {
          rank = <span>{ entry.tier.toTitleCase() } { entry.division } ({ seriesSpan })</span>
          miniRank = <span>{ entry.tier[0] }{ entry.division } ({ seriesSpan })</span>
        } else {
          rank = `${entry.tier.toTitleCase()} ${entry.division} (${entry.lp} LP)`
          miniRank = `${entry.tier[0]}${entry.division} (${entry.lp} LP)`
        }
      }

      return {
        key: name,
        name: <span>{ legendDot } { link }</span>,
        delta,
        value: currValue,
        rank,
        miniRank,
        titleRank,
        sortLP,
      }
    }).sort((a, b) => b.sortLP - a.sortLP)

    // Add unranked summoners to table
    order.filter((name) => filteredData[name] == null).forEach(name => {
      const link = formatRiotId(name, region, nicknames)
      const legendDot = <Dot disabled />

      tableData.push({
        key: name,
        name: <span>{ legendDot } { link }</span>,
        sortLP: -1,
        rank: 'Unranked',
        miniRank: 'Unranked',
        titleRank: '(Unranked)',
      })
    })

    const ScaleSelect = ({ value }) => {
      const hoverScale = {
        '1m': '1 month of',
        '2m': '2 months of',
        '3m': '3 months of',
        'max': 'all available',
      }

      const hoverClass = scale === value ? styles.scaleSelected : styles.scaleUnselected

      const contents = (
        <span onClick={ () => this.setScale(value) } className={ hoverClass }>
          { value }
        </span>
      )

      return (
        <HoverTip
          text={ `Show ${hoverScale[value]} data` }
          position="bottom"
          contents={ contents }
        />
      )
    }

    const Star = () => {
      const content = (
        <FontAwesomeIcon
          icon={ starred ? faStar : faStarEmpty }
          size="xs"
          onClick={ this.toggleStar }
          className={ starred ? styles.starred : styles.star }
        />
      )

      return (
        <HoverTip
          text={ `${starred ? 'Unstar' : 'Star'} this leaderboard` }
          position="bottom"
          contents={ content }
        />
      )
    }

    const Reload = () => {
      const timeSinceRefresh = (new Date() / 1000) - lastRefresh
      const enableRefresh = !disableRefresh && timeSinceRefresh > (15 * 60)

      let refreshClass = styles.refresh

      if (!enableRefresh) {
        refreshClass = styles.disabledRefresh
      } else if (refreshing) {
        refreshClass = styles.refreshing
      }

      const content = (
        <FontAwesomeIcon
          icon={ faSyncAlt }
          size="xs"
          className={ refreshClass }
          onClick={ enableRefresh ? this.refreshLeaderboard : null }
          spin={ refreshing }
        />
      )

      if (disableRefresh || refreshing) {
        return content
      }

      const ago = timeAgo(lastRefresh * 1000)
      const refreshText = enableRefresh ? 'Refresh this leaderboard' : `Last refreshed ${ago}`

      return (
        <HoverTip
          text={ refreshText }
          position="bottom"
          contents={ content }
        />
      )
    }

    const chartControls = (
      <div className={ styles.scale }>
        <Reload />
        <Star />
        <span className={ styles.divider }>|</span>
        <ScaleSelect value='1m' />
        <ScaleSelect value='2m' />
        <ScaleSelect value='3m' />
        <ScaleSelect value='max' />
      </div>
    )

    let chart
    if (Object.keys(dataset).length === 0 || !hasData) {
      chart = (
        <div className={ styles.chart }>
          { chartControls }

          <div className={ styles.noChart }>
            No data to display.
          </div>
        </div>
      )
    } else {
      chart = (
        <div className={ styles.chart }>
          { chartControls }

          <Line
            data={ data }
            height={ isMobile ? 275 : 145 }
            options={ options }
            ref='chart' // eslint-disable-line react/no-string-refs
          />
        </div>
      )
    }

    const rowStyle = (order.length === 1) ? null : styles.tableRow

    const table = (
      <center>
        <Table
          showHeader={ false }
          columns={ columns }
          data={ tableData }
          rowClassName={ (record) => (filteredData[record.key] == null) ? styles.unrankedRow : rowStyle }
          onRowMouseEnter={ (record) => this.highlightDataset(record.key) }
          onRowMouseLeave={ (record) => this.unhighlightDataset(record.key) }
        />
      </center>
    )

    let message

    if (!hideAlert && announcement != null && announcement.title) {
      message = (
        <div className={ styles.message }>
          <FontAwesomeIcon icon={ faExclamation } className={ styles.exclaim } size="sm" />
          <a href={ announcement.url } rel="noreferrer noopener" target="_blank">{ announcement.title }</a>
          <FontAwesomeIcon
            icon={ faTimes }
            size="sm"
            title="Dismiss alert"
            className={ styles.close }
            onClick={ () => this.dismissAlert() }
          />
        </div>
      )
    }

    let pageTitle = leaderboardName

    if (tableData.length === 1) {
      pageTitle = `${ leaderboardName } — ${ tableData[0].titleRank }`
    }

    return (
      <div>
        <Helmet><title>{ `${ pageTitle } | zeal.gg` }</title></Helmet>
        { message }
        { chart }
        { table }
      </div>
    )
  }
}

Leaderboard.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Leaderboard
